import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router";
import LocalProviders from "./LocalProviders";
import OnlineProviders from "./OnlineProviders";
import IndexRouter from "./index-router";
// services
// import Log from "./scripts/services/log";
// import Glient from "./scripts/services/glient";
import { Storage } from "./scripts/services/storage";
// styles
import "./styles/index.less";

// log glient events
// Log.attachToRocWs(Constants.WsType.Glient, Glient);
// add app container

window.addEventListener("DOMContentLoaded", (/*event*/) => {
	const root = createRoot(document.getElementById("container")!);

	Storage.migrate();
	root.render(
		<StrictMode>
			<LocalProviders injectAdditionalContextProviders={true}>
				<OnlineProviders>
					<RouterProvider router={IndexRouter} />
				</OnlineProviders>
			</LocalProviders>
		</StrictMode>
	);
});
