import { createHashRouter, Navigate } from "react-router";
import RootRenderElement from "./RootRenderElement";
import RouteListener from "./RouteListener";
// pages
import WelcomePage from "./scripts/pages/welcome-page";
import GatewaysPage from "./scripts/pages/GatewaysPage";
import ZigbeeNetworkMap from "./scripts/cmp/gateway/zigbee-network-map/zigbee-network-map";
import UsersPage from "./scripts/pages/UsersPage";
import StatsGerverPage from "./scripts/pages/stats-gerver-page";
import GLogPage from "./scripts/pages/glog-page";
// import GlientPlaygroundPage from "./scripts/pages/glient-playground-page";
import GupportPlaygroundPage from "./scripts/pages/gupport-playground-page";
import SupportUsersPage from "./scripts/pages/SupportUsersPage";
import TEditorPage from "./scripts/pages/teditor-page";
import CustomTEditorPage from "./scripts/pages/custom-teditor-page";
import BackUpPage from "./scripts/pages/BackUpPage";
import AboutPage from "./scripts/pages/about-page";
import LogPage from "./scripts/pages/log-page";
import SettingsPage from "./scripts/pages/settings-page";
import LocalizationPage from "./scripts/pages/localization-page";
import LoginPage from "./scripts/pages/login-page";
import AccountAddPage from "./scripts/pages/account-add-page";
import GeneralSettingsPage from "./scripts/pages/general-settings-page";
// types
import type { RouteObject } from "react-router";

const IndexRouter = [
	{
		path: "/",
		element: <RootRenderElement to="/gateways" />,
	},
	{
		path: "/login",
		element: <LoginPage />,
	},
	{
		path: "/accounts/add/:accountFor",
		element: <AccountAddPage />,
	},
	{
		path: "/*",
		element: <RouteListener />,
		children: [
			{
				path: "welcome",
				element: <WelcomePage />,
			},
			{
				path: "gateways",
				children: [
					{
						index: true,
						element: <GatewaysPage />,
					},
					{
						path: ":gatewayId",
						children: [
							{
								index: true,
								element: <Navigate replace={true} to="general" />,
							},
							{
								path: ":tab",
								children: [
									{
										index: true,
										element: <GatewaysPage />,
									},
									{
										path: "zigbeeNetworkMap",
										element: <ZigbeeNetworkMap />,
									},
								],
							},
						],
					},
				],
			},
			{
				path: "users",
				children: [
					{
						index: true,
						element: <UsersPage />,
					},
					{
						path: ":userId",
						children: [
							{
								index: true,
								element: <Navigate replace={true} to="general" />,
							},
							{
								path: ":tab",
								element: <UsersPage />,
							},
						],
					},
				],
			},
			{
				path: "stats/gerver",
				element: <StatsGerverPage />,
			},
			{
				path: "glogs",
				element: <GLogPage />,
			},
			// {
			// 	path: "playground/glient",
			// 	element: <GlientPlaygroundPage />,
			// },
			{
				path: "playground/gupport",
				element: <GupportPlaygroundPage />,
			},
			{
				path: "support/users",
				children: [
					{
						index: true,
						element: <SupportUsersPage />,
					},
					{
						path: ":userId",
						element: <SupportUsersPage />,
					},
				],
			},
			{
				path: "teditor",
				children: [
					{
						index: true,
						element: <TEditorPage />,
					},
					{
						path: ":tableId",
						children: [
							{
								index: true,
								element: <TEditorPage />,
							},
							{
								path: ":stringKey",
								element: <CustomTEditorPage />,
							},
						],
					},
				],
			},
			{
				path: "backups",
				element: <BackUpPage />,
			},
			{
				path: "utilities/localization",
				element: <LocalizationPage />,
			},
			{
				path: "about",
				element: <AboutPage />,
			},
			{
				path: "general-settings",
				element: <GeneralSettingsPage />,
			},
			{
				path: "logs",
				element: <LogPage />,
			},
			{
				path: "settings",
				element: <SettingsPage />,
			},
		],
	},
	{
		path: "*",
		element: <Navigate replace={true} to="/" />,
	},
] as const satisfies Array<RouteObject>;

export default createHashRouter(IndexRouter);
